
export const AppRoutes = {
    DashboardOverview: { path: "/" },
    CreateCustomer: { path: "/customer/new" },
    ViewCustomer: { path: "/customer/" },
    CreateAnimal: { path: "/animals/new" },
    ViewAnimal: { path: "/animals/" },
    CreateExam: { path: "/exams/new" },
    ViewExam: { path: "/exams/" },
    ViewWorker: { path: "/workers" },
    ExamDashboard: { path: "/exam/" },
    CreateService: { path: "/service/new" },
    ViewService: { path: "/services/" },
    ViewPayment: { path: "/payments/" },
    ViewCommission: { path: "/finance/commission" },


    
    TicketView: { path: "/ticket/u/:customerId/:petId" },
    CustomerDetailView: { path: "/customer/:customerId" },
    CustomerIndexPage: { path: "/customer/home"},
    
    // pages
    Transactions: { path: "/transactions" },
    Settings: { path: "/settings" },
    Billing: { path: "/billing" },
    Invoice: { path: "/invoice" },
    Signin: { path: "/login" },
    CustomerSignin: { path: "/customer/login" },

    Signup: { path: "/sign-up" },
    ForgotPassword: { path: "/forgot-password" },
    ResetPassword: { path: "/reset-password" },
    Lock: { path: "/lock" },
    NotFound: { path: "/404" },
    ServerError: { path: "/500" },
};